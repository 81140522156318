import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { UIKitProvider, Select, SelectChangeValue } from "./index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <Props of={Select} mdxType="Props" />
    <p>{`Regular Select`}</p>
    <UIKitProvider mdxType="UIKitProvider">
    <Select options={[{
        label: 'a',
        value: 'a'
      }, {
        label: 'b',
        value: 'b'
      }, {
        label: 'c',
        value: 'c'
      }]} value={{
        label: 'a',
        value: 'a'
      }} mdxType="Select" />
    </UIKitProvider>
    <p>{`Multi Select`}</p>
    <UIKitProvider mdxType="UIKitProvider">
    <Select isMulti options={[{
        label: 'a',
        value: 'a'
      }, {
        label: 'b',
        value: 'b'
      }, {
        label: 'c',
        value: 'c'
      }]} value={{
        label: 'a',
        value: 'a'
      }} mdxType="Select" />
    </UIKitProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      